import React from 'react';
import App from 'pages/_app';
import { datadogRum } from '@datadog/browser-rum';

const Root = () => <App />;

datadogRum.init({
  clientToken: `${process.env.DATADOG_CLIENT_TOKEN_MFA_CUSTOMER}`,
  applicationId: `${process.env.DATADOG_APPLICATION_ID_MFA_CUSTOMER}`,
  site: 'datadoghq.com',
  service: 'copilot-mfa-customer',
  env: JSON.stringify(process.env.STAGE),
  version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

export default Root;
