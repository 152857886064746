import React, { useEffect } from 'react';
import { Snackbar } from '@teamfabric/copilot-ui';
import { useRootContext } from '../../hooks/root';

export const FabricSnackbar = () => {
  const rootState = useRootContext();

  useEffect(() => {
    if (rootState.snackBarAttributes.showToaster) {
      setTimeout(() => {
        closeSnackbar();
      }, 6000);
    }
  }, [rootState]);

  const closeSnackbar = () => {
    rootState.setRootState({
      ...rootState,
      snackBarAttributes: {
        ...rootState.snackBarAttributes,
        showToaster: false,
        toasterMessage: '',
        kind: 'success',
      },
    });
  };

  return (
    <Snackbar
      dismissable
      height='60px'
      kind={rootState.snackBarAttributes.kind}
      label={rootState.snackBarAttributes.toasterMessage}
      show={rootState.snackBarAttributes.showToaster}
      position='top-center'
      onDismiss={() => closeSnackbar()}
      textColor='#FFFFFF'
      width='600px'
      isFloating
      isHighEmphasis
    />
  );
};
